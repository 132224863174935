body {
  background-color: rgba(255, 255, 255, 100);
}

.App {
  text-align: center;
}

table {
  white-space: nowrap;
  width: 100%;
}

thead tr {background: #F16551}

table {color: #EEEEEE}
tbody tr {background: #444}
tbody tr.female {background: #F16551}
tbody tr.male {background: #00567E}

.table-wrapper {
  text-align: center;
}

.table-wrapper h2 {
  color: #EEE;
  margin: 0;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.predict-wrapper {
  text-align: center;
}

.momentum-upload-wrapper {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chart-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
}

.chart-container {
  flex-grow: 1;
}

.chart-options-header {
  writing-mode: vertical-lr;
  font-size: 24px;
  background-color: black;
  color: white;
  padding: 10px 2px;
}

.chart-options-panel {
  display: flex;
  flex-direction: row;
}

.chart-options-panel-column {
  overflow-y: auto;
  padding: 5px;
  min-width: 100px;
}

.chart-options-panel-column .chip-wrapper {
  margin: 5px;
}
